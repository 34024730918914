import { Dialog, DialogContent, makeStyles } from "@material-ui/core"
import React from "react"
import ModalActions from "./ModalActions"
import ModalHeader from "./ModalHeader"

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    minWidth: "450px",
    margin: 0,
    padding: theme.spacing(2)
  }
}))

const ConfirmationModal = ({
  isForm,
  title,
  message,
  open,
  disabled,
  onClickSubmit,
  onClose = () => {},
  ...other
}: {
  isForm?: boolean
  title: string
  message: string | JSX.Element
  open: boolean
  disabled?: boolean
  onClickSubmit?: () => any
  onClose?: () => void
}) => {
  const classes = useStyles()

  const modalActionsProps = {
    isForm,
    onClickSubmit,
    onClose,
    disabled
  }

  return (
    <Dialog
      open={open && !disabled}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      scroll={"paper"}
      {...other}
      classes={{
        paper: classes.root
      }}
    >
      <ModalHeader onClose={onClose}>{title}</ModalHeader>
      <DialogContent>{message}</DialogContent>
      <ModalActions {...modalActionsProps} />
    </Dialog>
  )
}

export default ConfirmationModal
