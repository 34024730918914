import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import HubspotForm from "react-hubspot-form"

const useStyles = makeStyles(theme => ({
  form: {
    textAlign: "center",
    fontFamily: "ff-good-headline-web-pro-con,Helvetica,Arial,sans-serif",
    fontWeight: 400,
    fontSize: "3rem",
    letterSpacing: theme.spacing(0.5),
    padding: theme.spacing(5)
  }
}))

export default function Contact() {
  const classes = useStyles()

  return (
    <Grid
      container
      alignContent="center"
      justify="center"
      className={classes.form}
    >
      <Grid item md={1} lg={2} xl={3} />
      <Grid item xs={12} md={10} lg={8} xl={6}>
        <HubspotForm
          portalId="6969085"
          formId="0f943bf3-0dd0-458b-997d-3754f54663db"
          loading={<div>Loading...</div>}
        />
      </Grid>
      <Grid item md={1} lg={2} xl={3} />
    </Grid>
  )
}
