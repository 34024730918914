import { Submit } from "@/components/util/form"
import { Button, DialogActions } from "@material-ui/core"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ConfModal from "./ConfirmationModal"

const ModalActions = ({
  isForm,
  confirmable,
  confirmButtonText = "Ok",
  cancelButtonText = "Cancel",
  onClickSubmit = () => {},
  onClose = () => {},
  confirmationTitle = "Confirmation",
  confirmationMessage = "Are you sure?",
  disabled,
  ConfirmationModal = ConfModal
}: {
  isForm?: boolean
  confirmable?: boolean
  confirmButtonText?: string
  cancelButtonText?: string
  onClickSubmit?: () => void
  onClose?: () => void
  confirmationTitle?: string
  confirmationMessage?: string
  disabled?: boolean
  ConfirmationModal?: ({
    title,
    message,
    open,
    onClickSubmit,
    onClose,
    ...other
  }: {
    title: string
    message: string
    open: boolean
    onClickSubmit?: () => any
    onClose?: (() => void) | undefined
  }) => JSX.Element
}) => {
  const { t } = useTranslation()

  const [confirmOpen, setConfirmOpen] = useState(false)

  return (
    <>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          {t(cancelButtonText)}
        </Button>
        {confirmable ? (
          <Button
            color="primary"
            variant="text"
            onClick={() => setConfirmOpen(true)}
            disabled={disabled}
          >
            {t(confirmButtonText)}
          </Button>
        ) : isForm ? (
          <Submit
            label={t(confirmButtonText)}
            variant="text"
            color="primary"
            onClick={onClickSubmit}
            disabled={disabled}
          />
        ) : (
          <>
            <Button
              color="primary"
              variant="text"
              disabled={disabled}
              onClick={onClickSubmit}
            >
              {t(confirmButtonText)}
            </Button>
          </>
        )}
      </DialogActions>
      {confirmable && (
        <ConfirmationModal
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          title={t(confirmationTitle)}
          message={t(confirmationMessage)}
        />
      )}
    </>
  )
}

export default ModalActions
