import { gql, useQuery } from "@apollo/client"
import { Badge, IconButton } from "@material-ui/core"
import NotificationsIcon from "@material-ui/icons/Notifications"
import React from "react"
import { Link } from "react-router-dom"
import { ProfileNotifications } from "./__generated__/ProfileNotifications"

const GET_PROFILE = gql`
  query ProfileNotifications {
    me {
      id
      notificationsUnreadCount
    }
  }
`

type NotificationLinkProps = {
  noLink?: boolean
  isPlain?: boolean
}

export default function NotificationLink(props: NotificationLinkProps) {
  const { noLink, isPlain } = props

  const { data } = useQuery<ProfileNotifications>(GET_PROFILE)

  const count = data && data.me?.notificationsUnreadCount

  const NotificationBadge = () => (
    <Badge badgeContent={count} color={isPlain ? "default" : "secondary"}>
      <NotificationsIcon />
    </Badge>
  )

  if (noLink) {
    return (
      <IconButton color="inherit">
        <NotificationBadge />
      </IconButton>
    )
  }

  return (
    <IconButton color="inherit" component={Link} to="/notifications">
      <NotificationBadge />
    </IconButton>
  )
}
