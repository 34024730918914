import {
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import React from "react"

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    paddingBottom: 0
  }
}))
const ModalHeader = props => {
  const { children, onClose } = props
  const classes = useStyles()

  return (
    <DialogTitle disableTypography className={classes.title}>
      <Grid container>
        <Grid item xs={9}>
          <Typography variant="h6">{children}</Typography>
        </Grid>
        <Grid item xs={3}>
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </DialogTitle>
  )
}
export default ModalHeader
