import { UserRole } from "@/__generated__/globalTypes"
import { Preferences } from "@/components/auth/AuthContext"
import useAuth from "@/components/auth/useAuth"
import roles from "@/components/common/users/Roles"
import FormDialog from "@/components/util/dialog/FormDialog"
import { Select } from "@/components/util/form/fields"
import profileFragment from "@/graphql/fragments/profileFragment"
import useSnackbar from "@/helpers/add-snackbar"
import { usePersistedState } from "@/helpers/state"
import { gql, useMutation } from "@apollo/client"
import { DialogContentText } from "@material-ui/core"
import { Field, FormikHelpers } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import * as Yup from "yup"
import {
  StartImpersonation,
  StartImpersonationVariables
} from "./__generated__/StartImpersonation"

export const START_IMPERSONATION = gql`
  mutation StartImpersonation($organisationId: ID!, $role: UserRole!) {
    startImpersonation(organisationId: $organisationId, role: $role) {
      id
      ...profileFragment
    }
  }
  ${profileFragment}
`

interface Organisation {
  id: string
  name: string
}

type ImpersonateUserDialogProps = {
  open: boolean
  onClose: () => void
  organisation: Organisation
}

interface Values {
  role: UserRole
}

export default function ImpersonateUserDialog(
  props: ImpersonateUserDialogProps
) {
  const { open, onClose, organisation } = props
  const { user } = useAuth()
  const history = useHistory()
  const [, setPreferences] = usePersistedState<Preferences | undefined>(
    "preferences",
    true
  )

  const { addSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const RoleSchema = Yup.object().shape({
    role: Yup.string().required()
  })

  const initialValues = {
    role: (user?.role || "") as UserRole
  }

  const [startImpersonation, { client }] = useMutation<
    StartImpersonation,
    StartImpersonationVariables
  >(START_IMPERSONATION)

  return (
    <FormDialog
      open={open}
      onClose={onClose}
      title={organisation.name + t(" Impersonation")}
      onClickLabel={t("Save")}
      initialValues={initialValues}
      validationSchema={RoleSchema}
      onSubmit={(
        values: Values,
        { setSubmitting, setErrors }: FormikHelpers<Values>
      ) => {
        startImpersonation({
          variables: {
            organisationId: organisation.id,
            role: values.role
          }
        })
          .then(data => data?.data?.startImpersonation?.organisation?.assets)
          .then(assets => {
            setPreferences({
              asOfDate: user?.preferences?.asOfDate ?? user?.asOfDate ?? "",
              assetId: assets?.data[0]?.id ?? "",
              assetType: assets?.data[0]?.type ?? null,
              useLatestAsOfDate: user?.preferences?.useLatestAsOfDate ?? true
            })
            client.resetStore()
          })
          .then(() => setSubmitting(false))
          .then(() => addSnackbar("User Impersonated"))
          .then(() => onClose())
          .then(() => history.go(0))
          .catch(error => {
            if (
              error.graphQLErrors &&
              error.graphQLErrors[0]?.extensions?.validation
            ) {
              setSubmitting(false)
              setErrors(error.graphQLErrors[0]?.extensions.validation)
            } else {
              setSubmitting(false)
            }
          })
      }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogContentText>{t("Please select role")}</DialogContentText>
      <Field
        name="role"
        label="Role"
        select
        component={Select}
        margin="normal"
        fullWidth
        options={roles}
      />
    </FormDialog>
  )
}
