import { getMonth } from "date-fns"
import moment, { isMoment, Moment } from "moment"
/** @param 1-Indexed Calculations to conform to database */
export const DEFAULT_FISCAL_YEAR_START = 6 /// May 31st as QP

/** @param fiscalYearStartMonth {integer} One Indexed Fiscal Year */
export function priorFYEDate(
  asOfDate: string,
  fiscalYearStartMonth: number = DEFAULT_FISCAL_YEAR_START
): string {
  // If the current fiscal year end is the from the current month or previous year if otherwise
  const fyeMonth = fiscalYearStartMonth === 1 ? 11 : fiscalYearStartMonth - 2

  return moment(asOfDate).month() > fyeMonth
    ? moment(asOfDate)
        .month(fyeMonth) // Moment 0-Indexed
        .endOf("month")
        .format("YYYY-MM-DD")
    : moment(asOfDate)
        .subtract(1, "year")
        .month(fyeMonth) // Moment 0-Indexed
        .endOf("month")
        .format("YYYY-MM-DD")
}
export function isFiscalQuarterEndMonth(asOfDate: string, fys: number) {
  // !get Month is zero indexed fys is not
  return (getMonth(Date.parse(asOfDate)) + 1) % 3 === (fys + 2) % 3
}
/**ZERO INDEXED DATE eg MARCH JUNE OCT DEC */
export function isQuarterMonth(asOfDate: string) {
  return (
    getMonth(Date.parse(asOfDate)) === 2 ||
    getMonth(Date.parse(asOfDate)) === 5 ||
    getMonth(Date.parse(asOfDate)) === 8 ||
    getMonth(Date.parse(asOfDate)) === 11
  )
}

/** @param fiscalYearStartMonth {integer} One Indexed Fiscal Year */
export function priorFYSDate(
  asOfDate: string,
  fiscalYearStartMonth: number = DEFAULT_FISCAL_YEAR_START
): string {
  return moment(asOfDate).month() > fiscalYearStartMonth
    ? moment(asOfDate)
        .month(fiscalYearStartMonth - 1) // Moment 0-Indexed
        .startOf("month")
        .format("YYYY-MM-DD")
    : moment(asOfDate)
        .subtract(1, "year")
        .month(fiscalYearStartMonth - 1) // Moment 0-Indexed
        .startOf("month")
        .format("YYYY-MM-DD")
}

export function priorCQDate(asOfDate: string): string {
  switch (moment(asOfDate).month()) {
    case 0:
    case 1:
    case 2:
      return moment(asOfDate).month(11).endOf("month").format("YYYY-MM-DD")
    case 3:
    case 4:
    case 5:
      return moment(asOfDate).month(2).endOf("month").format("YYYY-MM-DD")
    case 6:
    case 7:
    case 8:
      return moment(asOfDate).month(5).endOf("month").format("YYYY-MM-DD")
    case 9:
    case 10:
    case 11:
      return moment(asOfDate).month(8).endOf("month").format("YYYY-MM-DD")
    default:
      return moment(asOfDate).format("YYYY-MM-DD")
  }
}

export function priorQDate(asOfDate: string): string {
  return moment(asOfDate)
    .subtract(3, "months")
    .endOf("month")
    .format("YYYY-MM-DD")
}

export function priorCYEDate(asOfDate: string): string {
  return moment(asOfDate)
    .subtract(1, "year")
    .month(11)
    .endOf("month")
    .format("YYYY-MM-DD")
}

export function priorMonthDate(asOfDate: string | Moment): string {
  return (isMoment(asOfDate) ? asOfDate : moment(asOfDate))
    .subtract(1, "months")
    .endOf("month")
    .format("YYYY-MM-DD")
}
export function isSameWeek(
  asOfDate: string | Moment,
  comparisonDate: string | Moment
) {
  const lastWeekDate = isMoment(asOfDate)
    ? asOfDate
    : moment(asOfDate).subtract(1, "week").startOf("isoWeek")
  return isMoment(comparisonDate)
    ? comparisonDate
    : moment(comparisonDate).isSameOrAfter(lastWeekDate)
}

/** @param fiscalYearStartMonth {integer} 1 Indexed Fiscal Year */
export function priorFQDate(
  asOfDate: string,
  fiscalYearStartMonth = DEFAULT_FISCAL_YEAR_START
): string {
  //priorQuarter /
  let quarters: number[] = []

  const fye = fiscalYearStartMonth === 1 ? 11 : fiscalYearStartMonth - 2 // moment 0 indexed
  quarters.push(fye)
  for (let i = 0; i < 3; i++) {
    if (quarters[i] + 3 > 11) {
      quarters.push(quarters[i] + 3 - 12)
    } else {
      quarters.push(quarters[i] + 3)
    }
  }
  quarters = quarters.sort((a, b) => a - b)

  for (let i = 0; i < quarters.length; i++) {
    if (quarters[i] >= moment(asOfDate).month()) {
      if (i === 0) {
        return moment(asOfDate)
          .subtract(1, "year")
          .month(quarters[3])
          .endOf("month")
          .format("YYYY-MM-DD")
      }

      return moment(asOfDate)
        .month(quarters[i - 1])
        .endOf("month")
        .format("YYYY-MM-DD")
    }
  }
  return moment(asOfDate).month(quarters[3]).endOf("month").format("YYYY-MM-DD")
}
/**
 * @param start {string} Format is YYYY-MM-DD
 *
 * @note First month not included in calculation */
export const monthEndDates = (
  /** @param start Not included in calculation */
  start: string,
  end: string
): Array<string> => {
  const endMoment = moment(end)
  let nextDate = moment(start)
  if (!endMoment.isValid() || !nextDate.isValid()) {
    throw Error("Incorrect date format")
  }
  const dates: Array<string> = []
  if (nextDate.isSameOrAfter(endMoment, "day")) {
    dates.push(start)
    // console.error("Start date is same or before end date in period calculation")

    return dates
  }

  nextDate = moment(nextDate).add(1, "month").endOf("month")
  while (nextDate.isSameOrBefore(endMoment, "day")) {
    dates.push(nextDate.format("YYYY-MM-DD"))
    nextDate = moment(nextDate).add(1, "month").endOf("month")
  }
  return dates
}

export const subtractMonths = (month: string, count: number = 0): string => {
  return moment(month)
    .subtract(count, "month")
    .endOf("month")
    .format("YYYY-MM-DD")
}

export const addMonths = (month: string, count: number = 0): string => {
  return moment(month).add(count, "month").endOf("month").format("YYYY-MM-DD")
}

export const subtractDates = (start: string, end: string): number => {
  const startDate = moment(start)
  const endDate = moment(end)
  return startDate.diff(endDate, "months")
}
