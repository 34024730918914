import { Button, Grid, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  heroImage: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    backgroundImage: 'url("/landing/hero@2x.jpg")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "580px",
    [theme.breakpoints.down("xs")]: {
      margin: -theme.spacing(0),
      justify: "flex-start"
    },
    marginBottom: theme.spacing(10)
  },
  heroBackdrop: {
    position: "relative",
    top: "18%",
    padding: "2em 8em 2em 2em",
    [theme.breakpoints.down("md")]: {
      padding: "1em 4em 1em 2em",
      top: "5%",
      bottom: "10%"
    },
    backgroundImage:
      "linear-gradient(90deg, rgba(0, 0, 0, 0.58) 0%, rgba(0, 0, 0, 0.18) 100%)"
  },
  heroHeading: {
    color: "#fafafa",
    fontSize: "2.5rem",
    fontFamily: "Nunito Sans, Roboto",
    fontWeight: "bolder",
    lineHeight: 1.3,
    letterSpacing: "2px",
    textShadow: "1px 1px black",
    paddingBottom: "0.5em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.8rem",
      lineHeight: 1.3
    }
  },
  heroSubHeading: {
    color: "#fafafa",
    fontSize: "1.8rem",
    fontFamily: "Nunito Sans, Roboto",
    lineHeight: 1.3,
    textShadow: "1px 1px black",
    paddingBottom: "0.8em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem"
    }
  }
}))

export default function PrimarySection() {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container className={classes.heroImage} justify="flex-end">
      <Grid item md={5}>
        <div className={classes.heroBackdrop}>
          <Typography
            component="h3"
            className={classes.heroHeading}
            variant="h3"
          >
            {t("home.hero.title")}
          </Typography>
          <Typography className={classes.heroSubHeading}>
            {t("home.hero.message")}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            component={Link}
            to="/contact"
          >
            {t("Contact Us")}
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}
