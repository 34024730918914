import FormConfirmationModal from "@/components/common/shared/forms/modals/FormConfirmationModal"
import ModalActions from "@/components/common/shared/forms/modals/ModalActions"
import ModalHeader from "@/components/common/shared/forms/modals/ModalHeader"
import DebugDisplay from "@/helpers/DebugDisplay"
import MomentUtils from "@date-io/moment"
import { Dialog, DialogContent, makeStyles } from "@material-ui/core"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { Form, Formik, FormikHelpers } from "formik"
import React, { ReactNode } from "react"
import LoadingModal from "../LoadingModal"

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: "400px",
    margin: 0,
    padding: theme.spacing(2)
  }
}))

export interface FormDialogProps {
  title: string
  open: boolean
  onClose?: () => void
  onClickLabel?: string
  initialValues: any
  validationSchema?: any
  onSubmit: (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => void | Promise<any>
  confirmable?: boolean
  confirmationTitle?: string
  confirmationMessage?: string
  children: ReactNode
  disableBackdropClick?: boolean
  disableEscapeKeyDown?: boolean
  displayFormState?: boolean
}

export default function FormDialog(props: FormDialogProps) {
  const {
    title,
    open,
    onClose,
    onClickLabel = "",
    initialValues,
    validationSchema,
    onSubmit,
    confirmable,
    confirmationTitle,
    confirmationMessage,
    displayFormState,
    ...other
  } = props
  const classes = useStyles()

  const modalActionProps = {
    confirmationTitle,
    confirmationMessage,
    confirmButtonText: onClickLabel,
    onClose,
    confirmable,
    ConfirmationModal: FormConfirmationModal
  }

  const dialogProps = {
    open,
    onClose,
    "aria-labelledby": "form-dialog-title",
    scroll: "paper" as "paper",
    classes: {
      paper: classes.root
    },
    ...other
  }

  return (
    <Dialog {...dialogProps}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          values,
          errors,
          dirty,
          isValid,
          isInitialValid,
          isValidating,
          initialErrors,
          initialTouched,
          initialStatus
        }) => (
          <Form>
            <ModalHeader id="form-dialog-title" onClose={onClose}>
              {title}
            </ModalHeader>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DialogContent>{props.children}</DialogContent>
            </MuiPickersUtilsProvider>
            <ModalActions
              {...{ ...modalActionProps, disabled: isSubmitting || !isValid }}
              isForm
            />
            {displayFormState ? (
              <DebugDisplay
                {...{
                  values,
                  errors,
                  dirty,
                  isValid,
                  isSubmitting,
                  isInitialValid,
                  isValidating,
                  initialErrors,
                  initialTouched,
                  initialStatus
                }}
              />
            ) : (
              <></>
            )}
            <LoadingModal open={isSubmitting} />
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
