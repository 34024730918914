import LanguageChoice from "@/components/app/LanguageChoice"
import {
  AppBar,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  useScrollTrigger
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import MenuIcon from "@material-ui/icons/Menu"
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state"
import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  topbar: {
    color: "black",
    backgroundColor: "white",
    width: "100%",
    padding: "0"
  },
  menu: {
    fontFamily: "NunitoSans-Bold",
    fontSize: 16,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: "dimgray"
  },
  button: {
    fontSize: "0.9rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem"
    }
  },
  logo: {
    height: "auto",
    [theme.breakpoints.down("xs")]: {}
  }
}))

type ElevationScrollProps = {
  children: ReactElement
  window?: () => Node | Window | undefined
}

function ElevationScroll(props: ElevationScrollProps) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  })
}

const imgPath = process.env.PUBLIC_URL + "/landing"

export default function HomeHeader({
  children,
  ...rest
}: {
  children: ReactElement
}) {
  const classes = useStyles()
  const { t } = useTranslation()
  const matches = useMediaQuery("(min-width:600px)")

  const MenuPopupState = props => {
    return (
      <PopupState variant="popover" popupId="hamburger-menu">
        {popupState => (
          <React.Fragment>
            <IconButton
              color="primary"
              aria-label="Top Menu"
              {...bindTrigger(popupState)}
            >
              <MenuIcon />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                component={Link}
                to="/contact"
                onClick={popupState.close}
              >
                {t("Contact Us")}
              </MenuItem>
              <MenuItem component={Link} to="/login" onClick={popupState.close}>
                {t("Login")}
              </MenuItem>
              <MenuItem component={LanguageChoice} />
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    )
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...rest}>
        <AppBar className={classes.topbar}>
          <Toolbar variant="dense">
            <Grid container wrap="wrap">
              <Grid container item xs={3} md={3}>
                <Link to="/">
                  <img
                    className={classes.logo}
                    src={imgPath + "/logo.png"}
                    alt="logo"
                  />
                </Link>
              </Grid>
              <Grid item container xs={9} md={9} justify="flex-end">
                {matches ? (
                  <>
                    <Grid
                      item
                      container
                      className={classes.menu}
                      justify="flex-end"
                    >
                      <Button
                        color="secondary"
                        size="large"
                        component={Link}
                        to="/contact"
                        className={classes.button}
                      >
                        {t("Contact Us")}
                      </Button>
                      <Button
                        color="inherit"
                        size="large"
                        component={Link}
                        to="/login"
                        className={classes.button}
                      >
                        {t("Login")}
                      </Button>
                      <LanguageChoice icon />
                    </Grid>
                  </>
                ) : (
                  <MenuPopupState />
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      {children}
    </React.Fragment>
  )
}
